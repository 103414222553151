<template>
  <div>
    <c-card title="작업위치(지도)" class="cardClassDetailForm" :noMarginPadding="true">
      <template slot="card-button">
        <q-btn-group outline>
          <!-- <c-btn v-if="editable&&isWriting" label="추가" icon="add" @btnClicked="addArea"/> -->
          <c-btn v-if="editable&&isWriting" label="지도" icon="place" @btnClicked="selectMap" />
        </q-btn-group>
      </template>
      <template slot="card-description">
        <font class="text-negative" style="font-size:0.8em;font-weight:300;">
          (※ 지도에 마우스 우클릭을 통해 위치를 지정하세요.)
        </font>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 container">
          <panZoom ref="panZoom" selector="#zoomerImage" :options="zoomer.options">
            <q-img
              id="zoomerImage"
              :src="mapSrc"
              :style="`width: ${(zoomer.height - 30) * ratio}px; height: ${zoomer.height - 30}px;`"
            >
              <q-menu
                touch-position
                context-menu
                @before-show="beforeShow"
              >
                <q-list dense style="min-width: 100px">
                  <q-item clickable v-close-popup @click="addArea">
                    <q-item-section avatar class="workPermit-mark-add">
                      <q-icon name="add" color="red" />
                    </q-item-section>
                    <q-item-section>작업위치 지정</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
              <c-map-marker
                v-for="(mark, idx) in workPermit.maps"
                :key="idx"
                :isSelected="true"
                class="mark-workPermit"
                :style="`transform: translate(${(mark.locationXcoordinate * zoomer.ratio) - 15}px, ${(mark.locationYcoordinate * zoomer.ratio) - 25}px);z-index: auto; user-select: auto; width: 30px; height: 30px;`"
              >
                <template v-slot:innerMark>
                  <!-- <q-card 
                    flat bordered 
                    :class="['workPermit-menu-card-icon']">
                    <q-btn-group outline >
                      <q-btn 
                        v-for="(icon, idx) in getMarkIcons(mark.resultTypes)"
                        :key="idx"
                        :icon="icon.icon"
                        flat dense 
                        color="primary" 
                        size="9px">
                      </q-btn>
                    </q-btn-group>
                  </q-card> -->
                  <q-menu 
                    :ref="'markedproxy' + mark.idx" 
                    :content-class="'workPermit-menu-layer'"
                    anchor="top right"
                    self="top left">
                    <q-card 
                      flat bordered 
                      :class="['workPermit-menu-card']">
                      <q-item class="workPermit-card-section">
                        <q-item-section class="workPermit-card-section cursor-pointer">
                          <c-multi-select
                            :disabled="!isWriting"
                            :editable="editable"
                            :comboItems="supplyItems"
                            :isObject="true"
                            class="mark-workPermit-multiselect"
                            valueText="sopWorkTypeName"
                            valueKey="sopWorkTypeCd"
                            itemText="codeName"
                            itemValue="code"
                            name="resultTypes"
                            label="위치에 따른 작업"
                            v-model="mark.resultTypes">
                            <!-- @datachange="datachange" -->
                          </c-multi-select>
                        </q-item-section>
                        <q-item-section side>
                          <q-icon name="delete" color="red" class="cursor-pointer" @click.stop="removePoint(idx)" />
                        </q-item-section>
                      </q-item>
                    </q-card>
                  </q-menu>
                </template>
              </c-map-marker>
            </q-img>
          </panZoom>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
// import VueDraggableResizable from 'vue-draggable-resizable'
// import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'swp-resources',
  // components: { VueDraggableResizable },
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 사업장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공정
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          gases: [],
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
        }
      }
    },
    supWorks: {
      type: Array,
      default: function() {
        return [];
      },
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zoomer: {
        height: 600,
        ratio: 1,
        style: 'width: 100%; height: 100%;',
        imageStyle: 'width: 90%; height: 90%;',
        value: 100,
        interval: 10,
        options: {
          minZoom: 0.5,
          maxZoom: 3,
          initialZoom: 1,
          initialX: 1800,
          initialY: 200,
          bounds: true,
          boundsPadding: 0.1,
          zoomDoubleClickSpeed: 1, 
          onTouch: function() {
            return false;
          }
        },
        menu: {
          offsetX: 0,
          offsetY: 0,
        },
        currentMap: {
          sopMapId: ''
        },
        maps: [],
      },
      showing: true,
      workIcons: [
        { sopWorkTypeCd: 'SPT0000001', icon: 'engineering' },
        { sopWorkTypeCd: 'SSWT000001', icon: 'dangerous' },
        { sopWorkTypeCd: 'SSWT000005', icon: 'flash_off' },
        { sopWorkTypeCd: 'SSWT000010', icon: 'local_fire_department' },
        { sopWorkTypeCd: 'SSWT000015', icon: 'hardware' },
        { sopWorkTypeCd: 'SSWT000020', icon: 'stairs' },
        { sopWorkTypeCd: 'SSWT000025', icon: 'public' },
      ],
      supplyItems: [],
      supplyOriginItems: [],
      sopMapId: '',
      ratio: 1,
      mapSrc: require('@/assets/images/no-image.png'),
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  watch: {
    supWorks: {
      handler: function () {
        this.setItems();
      },
      deep: true,
    },
    sopMapId() {
      this.$comm.previewImageParamTask(
        this.sopMapId,
        'WORK_MAP'
      ).then(_result => {
        this.mapSrc = _result;
      });
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.map.recent.url
      // code setting
      this.$comm.getComboItems('SOP_SUPPLEMENT_WORK_TYPE_CD').then(_result => {
        this.supplyOriginItems = _result
        this.$comm.getComboItems('SOP_PERMIT_TYPE_CD', null, 'Y').then(__result => {
          this.supplyOriginItems = this.$_.concat(this.supplyOriginItems, __result)
          this.setItems();
        });
      });
      // list setting
      this.setSize();
    },
    setSize() {
      this.zoomer.height = window.innerHeight - 200;
      this.zoomer.ratio = (this.zoomer.height - 30) / 575;
    },
    setItems() {
      this.supplyItems = [];
      if (this.workPermit.permitTypeCd === 'SPT0000001') {
        // 일반 허가서
        this.supplyItems = [
          this.$_.find(this.supplyOriginItems, { code: 'SPT0000001' })
        ]
      } else {
        // 화기 허가서 * 보충작업으로 빠짐
        // this.supplyItems = [
        //   this.$_.find(this.supplyOriginItems, { code: 'SPT0000005' })
        // ]
      }

      if (this.supWorks && this.supWorks.length > 0) {
        this.$_.forEach(this.supplyOriginItems, item => {
          if (this.$_.indexOf(this.supWorks, item.code) > -1 && this.$_.findIndex(this.supplyItems, { code: item.code }) === -1) {
            this.supplyItems.push(item)
          }
        })
      }
      // this.datachange();

      if (this.workPermit.maps && this.workPermit.maps.length > 0) {
        this.ratio = this.$_.clone(this.workPermit.maps[0].ratio)
        this.sopMapId = this.$_.clone(this.workPermit.maps[0].sopMapId);
      } else {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          useFlag: 'Y',
          plantCd: this.workPermit.plantCd
        };
        this.$http.request((_result) => {
          if (_result.data) {
            // 이후에 지도 src 정보도 가져와서 뿌릴 수 있도록 처리
            this.sopMapId = _result.data.sopMapId;
            this.ratio = _result.data.ratio;
          } else {
            window.getApp.$emit('ALERT', {
              title: '안내', 
              message: '지도정보를 등록 후 이용하시기 바랍니다.', 
              type: 'warning', // success / info / warning / error
            });
          }
        },);
      }
    },
    // getMarkIcons(resultTypes) {
    //   let icons = this.$_.filter(this.workIcons, item => {
    //     return this.$_.findIndex(resultTypes, { sopWorkTypeCd: item.sopWorkTypeCd }) > -1
    //   })
    //   return icons
    // },
    beforeShow(event) {
      let ratio = 575 / (this.zoomer.height - 30)
      this.$set(this.zoomer.menu, 'offsetX', event.offsetX * ratio)
      this.$set(this.zoomer.menu, 'offsetY', event.offsetY * ratio)
    },
    addArea() {
      this.workPermit.maps.push({
        sopMapResultId: uid(),
        sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
        sopMapId: this.sopMapId,  // 지도 일련번호
        locationXcoordinate: this.zoomer.menu.offsetX,  // 작업위치 X좌표
        locationYcoordinate: this.zoomer.menu.offsetY,  // 작업위치 Y좌표
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C',
        resultTypes: [],
      })
    },
    onDrag: function (x, y, item) {
      item.locationXcoordinate = x
      item.locationYcoordinate = y

      if (item.editFlag !== 'C') {
        item.editFlag = 'U'
        item.chgUserId = this.$store.getters.user.userId
      }
    },
    // datachange() {
    //   let tempArr = this.$_.cloneDeep(this.supplyItems);
    //   this.$_.forEach(tempArr, item => {
    //     item.disable = false;
    //   });
    //   if (this.workPermit.maps && this.workPermit.maps.length > 0) {
    //     this.$_.forEach(this.workPermit.maps, point => {
    //       if (point.resultTypes && point.resultTypes.length > 0) {
    //         this.$_.forEach(tempArr, item => {
    //           if (this.$_.findIndex(point.resultTypes, { sopWorkTypeCd: item.code}) > -1) {
    //             item.disable = true;
    //           }
    //         });
    //       }
    //     })
    //   }
    //   this.supplyItems = tempArr
    // },
    removePoint(idx) {
      let multiData = this.workPermit.maps[idx].resultTypes
      if (multiData && multiData.length > 0) {
        let tempArr = this.$_.cloneDeep(this.supplyItems);
        this.$_.forEach(tempArr, item => {
          if (this.$_.findIndex(multiData, { sopWorkTypeCd: item.code }) > -1) {
            item.disable = false;
          }
        })
        this.supplyItems = tempArr
      }
      if (!this.workPermit.deleteMaps) {
        this.workPermit.deleteMaps = [];
      }
      if (this.$_.findIndex(this.workPermit.deleteMaps, { sopMapResultId: this.workPermit.maps[idx].sopMapResultId }) === -1
        && this.workPermit.maps[idx].editFlag !== 'C') {
        this.workPermit.deleteMaps.push(this.workPermit.maps[idx])
      }

      this.workPermit.maps.splice(idx, 1)
    },
    selectMap() {
      this.popupOptions.title = '지도 검색'; 
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.workPermit.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/base/workMapPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.ratio = data[0].ratio
        this.$comm.previewImage({
          sysAttachFileId: data[0].sysAttachFileId,
          contentType: data[0].contentType,
        }).then(_result => {
          this.mapSrc = _result;
        });

        this.sopMapId = data[0].sopMapId;
        if (this.workPermit.maps && this.workPermit.maps.length > 0) {
          this.$_.forEach(this.workPermit.maps, map => {
            map.sopMapId = data[0].sopMapId;
            if (map.editFlag !== 'C') {
              map.editFlag = 'U'
              map.chgUserId = this.$store.getters.user.userId
            }
          })
        }
      }
    },
  }
};
</script>
<style lang="sass">
.markImage
  padding: 0px !important
  text-align: center
  font-size: 20px

.q-img__content
  .markImage.active
    border-width: 2px !important
    border-style: dashed !important
    border-color: hsla(0, 95%, 35%, 1) !important

.mark-workPermit
  background: unset !important
  cursor: pointer

.workPermit-menu-layer
  border-radius: 10px !important
  max-width: 600px !important
.workPermit-menu-card-icon
  padding-top: 5px
  padding-bottom: 5px
  margin: 6px
  width: auto
  position: absolute
  top: -35px
.workPermit-menu-card
  padding-top: 5px
  padding-bottom: 5px
  margin: 6px
  width: 350px
  .workPermit-card-section
    padding: 0

.mark-workPermit-multiselect
  padding-bottom: 0 !important
  margin-top: 15px !important
  .q-field
    padding: 20px 0 0 0 !important
</style>