var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "작업위치(지도)", noMarginPadding: true },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: { label: "지도", icon: "place" },
                        on: { btnClicked: _vm.selectMap },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "card-description" },
            [
              _c(
                "font",
                {
                  staticClass: "text-negative",
                  staticStyle: { "font-size": "0.8em", "font-weight": "300" },
                },
                [_vm._v(" (※ 지도에 마우스 우클릭을 통해 위치를 지정하세요.) ")]
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 container",
              },
              [
                _c(
                  "panZoom",
                  {
                    ref: "panZoom",
                    attrs: {
                      selector: "#zoomerImage",
                      options: _vm.zoomer.options,
                    },
                  },
                  [
                    _c(
                      "q-img",
                      {
                        style: `width: ${
                          (_vm.zoomer.height - 30) * _vm.ratio
                        }px; height: ${_vm.zoomer.height - 30}px;`,
                        attrs: { id: "zoomerImage", src: _vm.mapSrc },
                      },
                      [
                        _c(
                          "q-menu",
                          {
                            attrs: { "touch-position": "", "context-menu": "" },
                            on: { "before-show": _vm.beforeShow },
                          },
                          [
                            _c(
                              "q-list",
                              {
                                staticStyle: { "min-width": "100px" },
                                attrs: { dense: "" },
                              },
                              [
                                _c(
                                  "q-item",
                                  {
                                    directives: [
                                      {
                                        name: "close-popup",
                                        rawName: "v-close-popup",
                                      },
                                    ],
                                    attrs: { clickable: "" },
                                    on: { click: _vm.addArea },
                                  },
                                  [
                                    _c(
                                      "q-item-section",
                                      {
                                        staticClass: "workPermit-mark-add",
                                        attrs: { avatar: "" },
                                      },
                                      [
                                        _c("q-icon", {
                                          attrs: { name: "add", color: "red" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("q-item-section", [
                                      _vm._v("작업위치 지정"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._l(_vm.workPermit.maps, function (mark, idx) {
                          return _c("c-map-marker", {
                            key: idx,
                            staticClass: "mark-workPermit",
                            style: `transform: translate(${
                              mark.locationXcoordinate * _vm.zoomer.ratio - 15
                            }px, ${
                              mark.locationYcoordinate * _vm.zoomer.ratio - 25
                            }px);z-index: auto; user-select: auto; width: 30px; height: 30px;`,
                            attrs: { isSelected: true },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "innerMark",
                                  fn: function () {
                                    return [
                                      _c(
                                        "q-menu",
                                        {
                                          ref: "markedproxy" + mark.idx,
                                          refInFor: true,
                                          attrs: {
                                            "content-class":
                                              "workPermit-menu-layer",
                                            anchor: "top right",
                                            self: "top left",
                                          },
                                        },
                                        [
                                          _c(
                                            "q-card",
                                            {
                                              class: ["workPermit-menu-card"],
                                              attrs: { flat: "", bordered: "" },
                                            },
                                            [
                                              _c(
                                                "q-item",
                                                {
                                                  staticClass:
                                                    "workPermit-card-section",
                                                },
                                                [
                                                  _c(
                                                    "q-item-section",
                                                    {
                                                      staticClass:
                                                        "workPermit-card-section cursor-pointer",
                                                    },
                                                    [
                                                      _c("c-multi-select", {
                                                        staticClass:
                                                          "mark-workPermit-multiselect",
                                                        attrs: {
                                                          disabled:
                                                            !_vm.isWriting,
                                                          editable:
                                                            _vm.editable,
                                                          comboItems:
                                                            _vm.supplyItems,
                                                          isObject: true,
                                                          valueText:
                                                            "sopWorkTypeName",
                                                          valueKey:
                                                            "sopWorkTypeCd",
                                                          itemText: "codeName",
                                                          itemValue: "code",
                                                          name: "resultTypes",
                                                          label:
                                                            "위치에 따른 작업",
                                                        },
                                                        model: {
                                                          value:
                                                            mark.resultTypes,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              mark,
                                                              "resultTypes",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "mark.resultTypes",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "q-item-section",
                                                    { attrs: { side: "" } },
                                                    [
                                                      _c("q-icon", {
                                                        staticClass:
                                                          "cursor-pointer",
                                                        attrs: {
                                                          name: "delete",
                                                          color: "red",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.removePoint(
                                                              idx
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }